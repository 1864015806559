import { useEffect, useState } from "react";
import { config } from "@/utils/config";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { APP_ROUTES } from "@/routers/routes";
import useAuth from "@/hooks/authentication/useAuth";
import { theme } from "@/theme";
import { LogoIcon } from "@/assets/icons";

const LimitAccess = () => {
  const location = useLocation();
  const isUseSAML = config().ENABLED_SAML;
  const limitAccess = location.state?.limitAccess;
  const [isLimitAccess, setLimitAccess] = useState(limitAccess || false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (limitAccess) {
      setLimitAccess(limitAccess);
      navigate(location.pathname, { state: { limitAccess: false } });
    }
  }, [limitAccess, location.state?.limitAccess, location.pathname, navigate]);

  const handleSignOut = () => {
    if (isUseSAML) window.location.href = config().SAML_LOGOUT_URL;
    else {
      logout();
      navigate(APP_ROUTES.LOGIN.to);
    }
  };
  return (
    <Stack
      className="panel"
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Stack
        className="header"
        flexDirection="row"
        alignItems="center"
        gap={1}
        sx={{
          background: theme.palette.backgrounds.primary,
          borderBottom: "1px solid",
          padding: "0 24px",
          boxSizing: "border-box",
          height: 80,
          borderColor: theme.palette.neutralLight[400],
        }}
      >
        <LogoIcon />
        <Typography variant="Bold_18">
          Data <br />
          Visualisation
          <br />
          Platform
        </Typography>
      </Stack>
      <Stack
        className="content"
        sx={{
          flex: 1,
          overflow: "auto",
          padding: "20px 24px",
          background: theme.palette.neutralLight[100],
        }}
      >
        <Stack textAlign="center" gap={2} mt="20px">
          {isLimitAccess ? (
            <>
              <Box component="p">
                There are too many concurrent users logged into the Edu Hub Dvp now. Please wait for
                some time and try to login again
              </Box>
              <Box>
                <Button onClick={handleSignOut}>Ok</Button>
              </Box>
            </>
          ) : (
            <>Not found</>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LimitAccess;
