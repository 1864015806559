import { Theme } from "@mui/material/styles";

export default function Button(theme: Theme): Theme["components"] {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          minWidth: "auto",
          fontWeight: 700,
          textTransform: "capitalize",
          whiteSpace: "nowrap",
        },
        sizeLarge: {
          minHeight: 48,
          minWidth: 160,
          ...theme.typography.Bold_18,
        },
        sizeMedium: {
          minHeight: 40,
        },
        // sizeSmall: {
        //   minHeight: 40,
        //   ...theme.typography.Bold_14,
        // },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        sizeMedium: {
          width: 40,
          height: 40,
        },
        sizeSmall: {
          width: 30,
          height: 30,
        },
        sizeLarge: {
          width: 52,
          height: 52,
        },
      },
    },
  };
}
