import { useMutation } from "@tanstack/react-query";
import { NotifyService } from "@/helpers/notify";
import authApi from "@/services/auth.api";

const useReleaseToken = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      return await authApi.releaseToken();
    },

    onError: (error) => {
      NotifyService.error(`${error.message}`);
    },
  });

  return mutation;
};

export default useReleaseToken;
