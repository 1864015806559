import APIInstance from "@/utils/apiConfig";
import axios, { AxiosResponse } from "axios";
import { LoginResponse, ReleaseTokenResponse, TokenRequest } from "@/models/authentication";

const authApi = {
  login({ tokreq }: TokenRequest): Promise<AxiosResponse<LoginResponse>> {
    return APIInstance.post("/getusertoken", { tokreq });
  },
  getProfile() {
    return axios.get("/me");
  },
  releaseToken(): Promise<AxiosResponse<ReleaseTokenResponse>> {
    return APIInstance.get("/releasetoken");
  },
};

export default authApi;
